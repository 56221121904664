
import React from 'react';
import { api, CopyRightFooter } from '../../utils';
import { LoginForm } from '../loginForm/login';
import styles from './index.less';

if (!localStorage.browserCode) {
  const agent = navigator.userAgent.toLowerCase();
  const regStrChrome = /chrome\/[\d.]+/gi;
  if (agent.indexOf('chrome') > 0) {
    const chromeCode = agent.match(regStrChrome);
    localStorage.browserCode = chromeCode[0];
  }
}

export default class Login extends React.Component<{ store?; }> {
  render(): JSX.Element {
    return (
      <div className={styles.login}>
        <img
          className={styles.background}
          src={`${api.oss}/images/loginBg.png`}
        />
        <div className={styles.header}>
          <img
            className={styles.logo}
            src={`${api.oss}/images/logoWhite.png`}
          />
          <span>
            专业服装供应链服务商
          </span>
        </div>
        <div className={styles.content}>
          <LoginForm
            accountType={2}
            changePasswordPath="/egenie-ts-iac/findPassword"
            loginPath="/egenie-ts-iac/login"
            registryPath="/egenie-ts-iac/registry"
            sysType="pc_erp"
            tenantModule=""
          />
        </div>
        <CopyRightFooter/>
      </div>
    );
  }
}
